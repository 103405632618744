<template>
    <div class="relative flex h-full w-full flex-col">
        <!-- Top band navigation -->
        <accountant-top-band />

        <!-- Main -->
        <div class="flex h-[calc(100%-83px)] grow">
            <slot name="sidebar">
                <partial-sidebar
                    ref="sidebar"
                    class="h-full border-r border-gray-200 bg-white"
                    :menus="accountantNavigations"
                >
                    <template #menu="{ menus, openSidebar, isCollapsed }">
                        <ul
                            class="flex-1 overflow-auto pb-8 pt-6"
                            v-if="menus && menus.length > 0"
                        >
                            <accountant-sidebar-item
                                v-for="(menu, index) of menus"
                                :key="index"
                                :item="menu"
                                :is-collapsed="isCollapsed"
                                @open-sidebar="openSidebar"
                            />
                        </ul>
                    </template>
                </partial-sidebar>
            </slot>
            <main
                class="flex min-w-0 flex-1 flex-col"
                :class="{
                    'p-8': !noPadding,
                    'overflow-auto': !state.stickyStyle,
                }"
            >
                <slot v-if="state.mounted" />
            </main>
        </div>

        <template v-if="state.loading">
            <div
                class="absolute bottom-0 right-0 top-[82px] z-50 bg-white bg-opacity-60"
                :class="[isCollapsed ? 'left-20' : 'left-80']"
            >
                <span
                    class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                >
                    <base-loading size="lg" />
                </span>
            </div>
        </template>
    </div>

    <partial-notification @on-answer="onAnswer" />
</template>

<script setup>
import { accountantNavigations } from '@tenant/core/navigation'
import { ECHO_EVENTS } from '@tenant/utils/constants'

const { centralUser } = useAuth()
const emitter = useEmitter()
const router = useRouter()
const route = useRoute()

const sidebar = ref(null)
const state = reactive({
    loading: false,
    stickyStyle: false,
    mounted: false,
})

const isCollapsed = computed(() => sidebar.value?.isCollapsed)
const noPadding = computed(() => !!route.meta.noPadding)

onMounted(() => {
    emitter.on('set-loading', setLoading)
    emitter.on('set-sticky-style', setStickyStyle)
    state.mounted = true
})

onBeforeUnmount(() => {
    emitter.off('set-loading', setLoading)
    emitter.off('set-sticky-style', setStickyStyle)
    state.mounted = false
})

useListen(`chat.user.${centralUser.value.id}`, ECHO_EVENTS.CHAT_NEW)
useListen(`chat.user.${centralUser.value.id}`, ECHO_EVENTS.CHAT_MESSAGE)
useListen(`chat.email.user.${centralUser.value.id}`, ECHO_EVENTS.EMAIL_MESSAGE)

const setLoading = (value) => {
    state.loading = value
}

const setStickyStyle = (stickyStyle) => {
    state.stickyStyle = stickyStyle
}

const onAnswer = (notification) => {
    router.push({
        name: 'accountant.inbox.chat',
        query: {
            conversation: notification.chat_room_id,
        },
    })
}
</script>
